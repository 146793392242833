<template>
  <div class="chats">
    <div
        class="chat chat-left"
    >
      <div class="chat-avatar">
<!--        <b-avatar-->
<!--            size="36"-->
<!--            class="avatar-border-2 box-shadow-1"-->
<!--            variant="transparent"-->
<!--            v-if="chatData.creatorUserId !== admin.userId"-->
<!--            :src="chatData.userId === formattedChatData.contact.id ? formattedChatData.contact.avatar : profileUserAvatar"-->
<!--        />-->
      </div>
      <div class="chat-body">
        <div class="chat-content position-relative " :class="{'pb-2': chatData.creatorUserId === admin.userId}">
          <img v-if="chatData.fileData!== undefined && chatData.fileData!== null && chatData.fileData.base64.includes('jpg')"
               :src="baseUrl + chatData.fileData.base64" class="w-100 rounded object-cover object-center mb-2" alt="">
          <audio preload="metadata" :src="baseUrl +chatData.fileData.base64" type="audio/mp3 " style="min-width: 12rem"
                 v-if="chatData.fileData!== undefined && chatData.fileData!==null && chatData.fileData.base64.includes('mp3')" controls
                 class="w-100 rounded"></audio>
          <video controls v-if="chatData.fileData!== undefined && chatData.fileData!==null && chatData.fileData.base64.includes('mp4') "
                 :src="baseUrl + chatData.fileData.base64" style="max-height: 30rem" class="w-100 h-40 rounded"></video>
          <p class="mb-1">{{ chatData.messageBody }}</p>

          <div class="cursor-pointer d-flex flex-row delete"
               v-if="chatData.creatorUserId === admin.userId"
               v-b-modal.modal-delete
               @click="showDeleteModal(chatData.messageId)"
          >
            <feather-icon icon="TrashIcon" size="15" class="text-danger"/>
          </div>

          <small v-if="chatData.creatorUserId === admin.userId && chatData.isDelivered && !chatData.isRead"
                 class="deliver ">
            <feather-icon icon="CheckIcon" size="15"/>
          </small>

          <small v-if="chatData.creatorUserId === admin.userId && chatData.isRead" class="deliver ">
            <DoubleCheckIcon class="text-primary"/>
          </small>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {computed} from '@vue/composition-api'
import {BAvatar} from 'bootstrap-vue'
import DoubleCheckIcon from "@/views/components/icons/DoubleCheckIcon";
import {MessageDeleteRequest} from "@/libs/Api/Message";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
// import { mapGetters } from "vuex";

export default {
  data() {
    return {
      baseUrl: 'https://api.maxterms.com/media/gallery/chatMedia/',
      deleteItem: null,
    }
  },
  components: {
    DoubleCheckIcon,
    BAvatar,
  },
  props: {
    chatData: {
      type: Object,
      required: true,
    },
    admin: {
      type: Object,
      required: true,
    },
    // user: {
    //   type: Object,
    //   required: true,
    // },
    profileUserAvatar: {
      type: String,
      required: true,
    },
  },
  computed: {
    // ...mapGetters(["SocketId"]),
  },
  mounted() {
    // console.log(this.chatData.userInfo.userId)
  },
  methods: {
    showDeleteModal(param) {
      let _this = this;
      _this.$emit('deleteItem', param)
    },
  },
  setup(props) {
    const formattedChatData = computed(() => {
      const contact = {
        id: props.chatData.userId,
        avatar: `https://api.maxterms.com/${props.user.selfieFileData}`,
      }

      let chatLog = []
      if (props.chatData.chat) {
        chatLog = props.chatData.chat.chat
      }

      const formattedChatLog = []
      let chatMessageSenderId = chatLog[0] ? chatLog[0].senderId : undefined
      let msgGroup = {
        sender: chatMessageSenderId,
        messages: [],
      }

      chatLog.forEach((msg, index) => {
        if (chatMessageSenderId === msg.senderId) {
          msgGroup.messages.push({
            msg: msg.message,
            time: msg.time,
          })
        } else {
          chatMessageSenderId = msg.senderId
          formattedChatLog.push(msgGroup)
          msgGroup = {
            senderId: msg.senderId,
            messages: [{
              msg: msg.message,
              time: msg.time,
            }],
          }
        }
        if (index === chatLog.length - 1) formattedChatLog.push(msgGroup)
      })

      return {
        formattedChatLog,
        contact,
        profileUserAvatar: props.profileUserAvatar,
      }
    })

    return {
      formattedChatData,
    }
  },
}
</script>

<style scoped>
.ticket-img {
  width: 10rem;
  height: 10rem;
  margin-top: 10px;
  background-size: contain;
  background-repeat: no-repeat;
}

.deliver {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.delete {
  position: absolute;
  bottom: 5px;
  left: 5px;
}
</style>
