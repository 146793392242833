import WebServiceRequest from '../Api/WebServiceRequest'

class ChannelCreateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Channel/Create')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class ChannelDeleteRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setId(id){
        super.setTag('Channel/Delete?id='+id)
    }
}
class ChannelGetAllRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Channel/GetAll');
    }
    setParams(data){
        super.setRequestParam(data);
    }
}
class ChannelGetRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Channel/Get')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class ChannelUpdateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Channel/Update')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}

// ChannelUser
class ChannelUserCreateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('ChannelUser/Create')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class ChannelUserDeleteRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setId(id){
        super.setTag('ChannelUser/Delete?id='+id)
    }
}
class ChannelUserGetAllRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('ChannelUser/GetAll');
    }
    setParams(data){
        super.setRequestParam(data);
    }
}
class ChannelUserGetRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('ChannelUser/Get')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class ChannelUserUpdateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('ChannelUser/Update')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class ChannelUserGetUserByChannelIdRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('ChannelUser/GetUsersByChannelId')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class ChannelUserDeleteUserFromChannelRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('ChannelUser/DeleteUserFromChannel')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
export {
    ChannelCreateRequest,
    ChannelDeleteRequest,
    ChannelGetAllRequest,
    ChannelGetRequest,
    ChannelUpdateRequest,
    ChannelUserCreateRequest,
    ChannelUserDeleteRequest,
    ChannelUserGetAllRequest,
    ChannelUserGetRequest,
    ChannelUserUpdateRequest,
    ChannelUserGetUserByChannelIdRequest,
    ChannelUserDeleteUserFromChannelRequest,
};
